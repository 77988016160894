import { Booking } from "@octomatic-labs/my-ergon-lab-models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bookingsApiSlice = createApi({
  reducerPath: "bookingsApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Bookings"],
  endpoints: (builder) => ({
    createBooking: builder.mutation<
      Booking,
      { userId: number; slotId: number; date: Date }
    >({
      query: (newBooking) => ({
        url: "bookings",
        method: "POST",
        body: newBooking,
      }),
    }),
  }),
});

export const { useCreateBookingMutation } = bookingsApiSlice;
