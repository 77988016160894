import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from "react-redux";
import { profileApiSlice } from "@/store/api/profileService";
import { membersApiSlice } from "@/store/api/membersService";
import { slotsApiSlice } from "@/store/api/slotsService";
import notificationMiddleware from "@/modules/notifications/store/notificationMiddleware";
import { bookingsApiSlice } from "./api/bookingsService";
import { programsApiSlice } from "./api/programsService";
import { adminsApiSlice } from "./api/adminsService";
import { subscriptionsApiSlice } from "./api/subscriptionsService";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApiSlice.middleware,
      membersApiSlice.middleware,
      slotsApiSlice.middleware,
      bookingsApiSlice.middleware,
      programsApiSlice.middleware,
      adminsApiSlice.middleware,
      subscriptionsApiSlice.middleware,
      notificationMiddleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
