import { CreateSubscriptionPayload, EditSubscriptionPayload, Program, Subscription } from "@octomatic-labs/my-ergon-lab-models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const subscriptionsApiSlice = createApi({
  reducerPath: "subscriptionsApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Subscriptions"],
  endpoints: (builder) => ({
    getSubscriptions: builder.query<Subscription[], void>({
      query: () => "subscriptions",
      transformResponse: (response: Subscription[] | undefined) => response || [],
      providesTags: ["Subscriptions"]
    }),
    getSubscription: builder.query<Subscription, number>({
      query: (id) => `subscriptions/${id}`,
    }),
    createSubscription: builder.mutation<Subscription, CreateSubscriptionPayload>({
      query: (newSubscription) => ({ url: "subscriptions", method: "POST", body: newSubscription })
    }),
    updateSubscription: builder.mutation<EditSubscriptionPayload, EditSubscriptionPayload>({
      query: (updates) => {
        const {id, ...body} = updates;
        return { url: `subscriptions/${id}`, method: "PATCH", body };
      },
      invalidatesTags: ['Subscriptions'],
    }),
    getSubscriptionPrograms: builder.query<Program[], number>({
      query: (subscriptionId) => `subscriptions/${subscriptionId}/programs`,
      transformResponse: (response: Program[]) => response || [],
    }),
  })
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useGetSubscriptionProgramsQuery,
} = subscriptionsApiSlice;