import { Slot } from "@octomatic-labs/my-ergon-lab-models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const slotsApiSlice = createApi({
  reducerPath: "slotsApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Slots"],
  endpoints: (builder) => ({
    getAllSlots: builder.query<Slot[], void>({
      query: () => "slots",
      transformResponse: (response: Slot[] | undefined) => {
        return response || [];
      },
      providesTags: [{ type: "Slots", id: "LIST" }],
    }),
    updateSlots: builder.mutation<any, { id: number; isActive: boolean }[]>({
      query: (updates) => ({
        url: "slots",
        method: "PATCH",
        body: updates,
      }),
    }),
    updateSingleSlot: builder.mutation<
      Slot,
      // @ts-expect-error will update with new data structure for gyms and slots
      Pick<Slot, "id" | "isActive">
    >({
      query: (update) => ({
        url: `slots/${update.id}`,
        method: "PATCH",
        body: update,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "Slots", id: result.id }] : [],
    }),
  }),
});

export const {
  useGetAllSlotsQuery,
  useUpdateSlotsMutation,
  useUpdateSingleSlotMutation,
} = slotsApiSlice;
