import { RootState } from "@/store";
import { adminsApiSlice } from "@/store/api/adminsService";
import { User } from "@octomatic-labs/my-ergon-lab-models";
import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

type AdminsState = {
  admins: EntityState<User> & { current: number | null };
};

const adminsAdapter = createEntityAdapter<User>();

const initialState: AdminsState = {
  admins: adminsAdapter.getInitialState({ current: null }),
};

const slice = createSlice({
  name: "Admins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      adminsApiSlice.endpoints.getAdmins.matchFulfilled,
      (state, action) => {
        adminsAdapter.setAll(state.admins, action.payload);
      }
    );
  },
});

export default slice.reducer;

export const {
  selectById: selectAdminById,
  selectAll: selectAllAdmins,
  selectEntities: selectAllAdminEntities,
} = adminsAdapter.getSelectors((state: RootState) => state.admins.admins);
