import {
  ActionFromReducersMapObject,
  Reducer,
  StateFromReducersMapObject,
  combineReducers,
} from "@reduxjs/toolkit";

import profile from "@/modules/profile/store/slice";
import { profileApiSlice } from "@/store/api/profileService";
import members from "@/modules/members/store/slice";
import { membersApiSlice } from "@/store/api/membersService";
import calendar from "@/modules/callendar/store/slice";
import programs from "@/modules/programs/store/slice";
import { slotsApiSlice } from "@/store/api/slotsService";
import { bookingsApiSlice } from "./api/bookingsService";
import { programsApiSlice } from "./api/programsService";
import notifications from "@/modules/notifications/store/slice";
import { adminsApiSlice } from "./api/adminsService";
import admins from "@/modules/admins/store/slice";
import { subscriptionsApiSlice } from "./api/subscriptionsService";
import subscriptions from "@/modules/subscriptions/store/slice";

const reducers = {
  profile,
  [profileApiSlice.reducerPath]: profileApiSlice.reducer,
  members,
  [membersApiSlice.reducerPath]: membersApiSlice.reducer,
  calendar,
  [slotsApiSlice.reducerPath]: slotsApiSlice.reducer,
  [bookingsApiSlice.reducerPath]: bookingsApiSlice.reducer,
  [programsApiSlice.reducerPath]: programsApiSlice.reducer,
  programs,
  [adminsApiSlice.reducerPath]: adminsApiSlice.reducer,
  admins,
  [subscriptionsApiSlice.reducerPath]: subscriptionsApiSlice.reducer,
  subscriptions,
  notifications
};

type ReducersMapObject = typeof reducers;

const rootReducer: Reducer<
  StateFromReducersMapObject<ReducersMapObject>,
  ActionFromReducersMapObject<ReducersMapObject>
> = combineReducers(reducers);

export default rootReducer;
