import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
import { IconButtonAnimate } from '@/components/animate';
import Logo from '@/components/logo';
import MenuPopover from '@/components/MenuPopover';
import { useAuthContext } from '@/auth/useAuthContext';
import { PATH_AUTH } from '@/routes/paths';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { user, logout } = useAuthContext();

  const navigate = useNavigate();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login);
      handleClose();
    } catch (error) {
      console.error('Unable to logout');
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.4),
            },
          }),
        }}
      >
        <Logo disabledLink={true} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user.email}`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Αποσύνδεση
        </MenuItem>
      </MenuPopover>
    </>
  );
}
