import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SettingsProvider } from "./components/settings";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./routes";
import { store } from "./store";
import { Provider as ReduxProvider } from "react-redux";

const browserRouter = createBrowserRouter([
  {
    id: "root",
    path: "*",
    element: <App />,
    children: ROUTES,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <SettingsProvider>
      <CollapseDrawerProvider>
        <RouterProvider router={browserRouter} />
      </CollapseDrawerProvider>
    </SettingsProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
