import { RootState } from "@/store";
import { programsApiSlice } from "@/store/api/programsService";
import { Program } from "@octomatic-labs/my-ergon-lab-models"
import { EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit"

type ProgramsState = {
  programs: EntityState<Program>;
}

const programsAdapter = createEntityAdapter<Program>();

const initialState: ProgramsState = {
  programs: programsAdapter.getInitialState()
};

const slice = createSlice({
  name: "Programs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      programsApiSlice.endpoints.getAllPrograms.matchFulfilled,
      (state, action) => {
        programsAdapter.setAll(state.programs, action.payload);
      }
    )
  }
});

export default slice.reducer;

export const {
  selectById: selectProgramById,
  selectAll: selectAllPrograms
} = programsAdapter.getSelectors((state: RootState) => state.programs.programs)