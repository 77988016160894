import { ElementType, Suspense, lazy } from "react";
import LoadingScreen from "../components/loading-screen";

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// AUTH
export const LoginPage = Loadable(lazy(() => import("../auth/LoginPage")));
export const VerifyCodePage = Loadable(
  lazy(() => import("../auth/VerifyCodePage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("../auth/NewPasswordPage"))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import("../auth/ResetPasswordPage"))
);

// ADMIN PAGES
export const DashboardPage = Loadable(lazy(() => import("../modules/dashboard/views/Dashboard")));
export const MembersPage = Loadable(lazy(() => import('../modules/members/views/Members')));
export const CreateEditMemberPage = Loadable(lazy(() => import("../modules/members/views/CreateEditMember")));
export const ProgramsPage = Loadable(lazy(() => import("../modules/programs/views/Programs")));
export const CreateEditProgramPage = Loadable(lazy(() => import("../modules/programs/views/CreateEditProgram")));
export const SubscriptionsPage = Loadable(lazy(() => import("../modules/subscriptions/views/Subscriptions")));
export const CreateEditSubscriptionPage = Loadable(lazy(() => import("../modules/subscriptions/views/CreateEditSubscription")));

export const AdminCalendarPage = Loadable(lazy(() => import("../modules/callendar/views/AdminCalendar")));
export const AdminsPage = Loadable(lazy(() => import("../modules/admins/views/Admins")));

//MEMBER PAGES
export const MemberCalendarPage = Loadable(lazy(() => import("../modules/callendar/views/MemberCalendar")));
export const ProfilePage = Loadable(lazy(() => import("../modules/profile/views/Profile")));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
