import { Navigate, useRoutes } from "react-router-dom";
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
import {
  MemberCalendarPage,
  CreateEditMemberPage,
  DashboardPage,
  LoginPage,
  MembersPage,
  NewPasswordPage,
  ProfilePage,
  ResetPasswordPage,
  VerifyCodePage,
  AdminCalendarPage,
  ProgramsPage,
  Page404,
  AdminsPage,
  SubscriptionsPage,
  CreateEditProgramPage,
  CreateEditSubscriptionPage,
} from "./elements";
import GuestGuard from "../auth/GuestGuard";
import AuthGuard from "@/auth/AuthGuard";
import RoleBasedGuard from "@/auth/RoleBasedGuard";
import { UserRole } from "@octomatic-labs/my-ergon-lab-models";

export const ROUTES = [
  // Auth
  {
    path: "auth",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <LoginPage />
          </GuestGuard>
        ),
      },
      {
        element: <CompactLayout />,
        children: [
          { path: "reset-password", element: <ResetPasswordPage /> },
          { path: "new-password", element: <NewPasswordPage /> },
          { path: "verify", element: <VerifyCodePage /> },
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // { element: <Navigate to={PATHS.root} replace />, index: true },
      {
        element: <Navigate to="/dashboard" replace />,
        index: true,
      },
      {
        path: "dashboard",
        element: (
          <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
            <DashboardPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: "admins",

        children: [
          {
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN]}>
                <AdminsPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: "members",

        children: [
          {
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                <MembersPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "create",
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                    <CreateEditMemberPage />
                  </RoleBasedGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: "edit/:id",
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                <CreateEditMemberPage isEdit />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "programs",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                <ProgramsPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "create",
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                    <CreateEditProgramPage />
                  </RoleBasedGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: "edit/:id",
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                <CreateEditProgramPage isEdit />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "subscriptions",
        children: [
          {
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                <SubscriptionsPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: "create",
            children: [
              {
                element: (
                  <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                    <CreateEditSubscriptionPage />
                  </RoleBasedGuard>
                ),
                index: true,
              },
            ],
          },
          {
            path: "edit/:id",
            element: (
              <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
                <CreateEditSubscriptionPage isEdit />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: "admin-calendar",
        element: (
          <RoleBasedGuard roles={[UserRole.ADMIN, UserRole.TRAINER]}>
            <AdminCalendarPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: "profile",
        element: (
          <RoleBasedGuard roles={[UserRole.MEMBER]}>
            <ProfilePage />
          </RoleBasedGuard>
        ),
      },
      {
        path: "calendar",
        element: (
          <RoleBasedGuard roles={[UserRole.MEMBER]}>
            <MemberCalendarPage />
          </RoleBasedGuard>
        ),
      },
      { path: "404", element: <Page404 /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

export default function Router() {
  return useRoutes(ROUTES);
}
