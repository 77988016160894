import {
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
// config
import { FIREBASE_API } from "../config-global";
//
import { FirebaseContextType } from "./types";
import { useDispatch } from "@/store";
import { profileApiSlice } from "@/store/api/profileService";

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          setUser(user);
          const accessToken = await user.getIdToken(true);
          localStorage.setItem("accessToken", accessToken);
          setIsAuthenticated(true);
        } else {
          setUser(null);
          localStorage.removeItem("accessToken");
          setIsAuthenticated(false);
        }
        setIsInitialized(true);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const res = await signInWithEmailAndPassword(AUTH, email, password);
    const accessToken = await res.user?.getIdToken(true);
    localStorage.setItem("accessToken", accessToken);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
    dispatch(profileApiSlice.util.resetApiState());
  }, []);

  const sendResetPasswordEmail = useCallback(async (email: string) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isAuthenticated,
      user,
      method: "firebase",
      login,
      logout,
      sendResetPasswordEmail,
    }),
    [
      isAuthenticated,
      isInitialized,
      user,
      login,
      logout,
      sendResetPasswordEmail,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
