import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { slotsApiSlice } from "../../../store/api/slotsService";
import { Booking, Slot } from "@octomatic-labs/my-ergon-lab-models";
import { RootState } from "@/store";
import { bookingsApiSlice } from "@/store/api/bookingsService";

const slotsAdapter = createEntityAdapter<Slot>();
const bookingsAdapter = createEntityAdapter<Booking>();

const slotsSlice = createSlice({
  name: "slots",
  initialState: {
    slots: slotsAdapter.getInitialState(),
    bookings: bookingsAdapter.getInitialState(),
  },
  reducers: {
    // Any custom reducers if needed
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      slotsApiSlice.endpoints.getAllSlots.matchFulfilled,
      (state, action) => {
        slotsAdapter.setAll(state.slots, action.payload);
      }
    );

    builder.addMatcher(
      slotsApiSlice.endpoints.updateSlots.matchFulfilled,
      (state, { payload }) => {
        const updatedSlots = payload;

        const changes = updatedSlots.map(updatedData => ({
          id: updatedData.id,
          changes: {...updatedData}
        }))
        slotsAdapter.updateMany(state.slots, changes);
      }
    );

    builder.addMatcher(
      slotsApiSlice.endpoints.updateSingleSlot.matchFulfilled,
      (state, {payload}) => {
        const { id, ...updatedData } = payload;

        slotsAdapter.updateOne(state.slots, { id, changes: updatedData})
      }
    )

    builder.addMatcher(
      bookingsApiSlice.endpoints.createBooking.matchFulfilled,
      (state, {payload}) => {
        console.log(payload);
      }
    )
  },
});

export default slotsSlice.reducer;

// Custom selectors

export const { selectById: selectSlotById, selectAll: selectAllSlots } =
  slotsAdapter.getSelectors((state: RootState) => state.calendar.slots);
