import "./App.css";
import { AuthProvider } from "./auth/FirebaseContext";
import Router from "./routes";
import { HelmetProvider } from "react-helmet-async";
import ThemeProvider from "./theme";
import { useGetAllSlotsQuery } from "./store/api/slotsService";
import NotificationHandler from "./modules/notifications/components/NotificationHandler";
import SnackbarProvider from "./components/snackbar/SnackbarProvider";
import GlobalModal from "./modules/notifications/components/GlobalModal";

function App() {
  useGetAllSlotsQuery();

  return (
    <ThemeProvider>
      <SnackbarProvider>
        <NotificationHandler />
        <AuthProvider>
          <HelmetProvider>
            <Router />
          </HelmetProvider>
        </AuthProvider>
        <GlobalModal />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
