import { ReactNode } from 'react';
import { useAuthContext } from './useAuthContext';
import LoadingScreen from '@/components/loading-screen';
import { Navigate } from 'react-router-dom';
import { PATH_AUTH } from '@/routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} />;
  }


  return <>{children}</>;
}
