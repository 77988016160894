import { useDispatch, useSelector } from "@/store";
import { onModalClose } from "../store/slice";
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { forwardRef } from "react";
import { LoadingButton } from "@mui/lab";

export default function GlobalModal() {
  const dispatch = useDispatch();

  const {
    isOpen,
    isLoading,
    title,
    description,
    actionDescription,
    cancelActionDescription,
    confirmAction,
    confirmCancellation,
    customContent,
    maxWidth,
    fullWidth,
  } = useSelector((state) => state.notifications.modalProps);

  const handleClose = () => {
    dispatch(onModalClose());
    if (confirmCancellation !== null) (confirmCancellation as any)();
  };

  const handleConfirmation = () => {
    if (confirmAction !== null) (confirmAction as any)();
  };

  const handleKeyPress = (e: any) => {
    if (e.code === "Enter" || e.key === "Enter") handleConfirmation();
  };

  // In case description is a component, omit treating it as text
  const dialogContent =
    typeof description === "string" ? (
      <DialogContentText id="alert-dialog-slide-description">
        {description}
      </DialogContentText>
    ) : (
      description
    );

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={maxWidth as Breakpoint}
      fullWidth={fullWidth}
      onKeyDown={handleKeyPress}
    >
      <DialogTitle sx={{ mb: 3 }}>{title}</DialogTitle>
      <DialogContent sx={{ p: 3}}>{dialogContent}</DialogContent>
      {!customContent && (
        <DialogActions>
          <Button color="inherit" onClick={handleClose} disabled={isLoading}>
            {cancelActionDescription || "Cancel"}
          </Button>
          <LoadingButton
            onClick={handleConfirmation}
            variant="contained"
            loading={isLoading}
          >
            {actionDescription}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
