import { RootState } from "@/store";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

type Notification = {
  key: string;
  dismissed: boolean;
  title?: string;
  message?: string;
  variant: "error" | "default" | "success" | "warning" | "info";
};

const notificationsAdapter = createEntityAdapter<Notification>({
  selectId: (snackbar) => snackbar.key,
});

const initialState = {
  notifications: notificationsAdapter.getInitialState(),
  modalProps: {
    isOpen: false,
    isLoading: false,
    title: "",
    description: "",
    actionDescription: "",
    cancelActionDescription: "Ακύρωση",
    confirmAction: null,
    confirmCancellation: null,
    maxWidth: "lg",
    fullWidth: false,
    customContent: false,
  },
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // ON ENQUEUE SNACKBAR
    onEnqueueSnackbar(state, { payload: snackbar }) {
      notificationsAdapter.addOne(state.notifications, snackbar);
    },

    // ON CLOSE SNACKBAR
    onCloseSnackbar(state, { payload: key }) {
      // Set notification object as dismissed
      notificationsAdapter.updateOne(state.notifications, {
        id: key,
        changes: {
          dismissed: true,
        },
      });
    },

    // ON REMOVE SNACKBAR
    onRemoveSnackbar(state, { payload: key }) {
      notificationsAdapter.removeOne(state.notifications, key);
    },

    onModalOpen(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    onModalActionLoading(state, { payload }) {
      state.modalProps.isLoading = payload;
    },

    onModalActionComplete(state) {
      state.modalProps.isOpen = false;
    },

    onModalClose(state) {
      state.modalProps.isOpen = false;
    },
  },
});

export const {
  onEnqueueSnackbar,
  onCloseSnackbar,
  onRemoveSnackbar,
  onModalOpen,
  onModalActionComplete,
  onModalClose,
  onModalActionLoading
} = notificationSlice.actions;
export default notificationSlice.reducer;

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationByID,
} = notificationsAdapter.getSelectors(
  (state: RootState) => state.notifications.notifications
);
