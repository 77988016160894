import { User } from "@octomatic-labs/my-ergon-lab-models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminsApiSlice = createApi({
  reducerPath: "AdminsApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Admins", "CurrentAdmin"],
  endpoints: (builder) => ({
    getAdmins: builder.query<User[], void>({
      query: () => "users/admins",
      transformResponse: (response: User[] | undefined) => response || [],
      providesTags: ["Admins"],
    }),
    getAdmin: builder.query<User, number | string>({
      query: (id) => `users/admins/${id}`,
      providesTags: ["CurrentAdmin"],
    }),
  }),
});

export const { useGetAdminsQuery, useGetAdminQuery } = adminsApiSlice;
