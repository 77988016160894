import { slotsApiSlice } from "@/store/api/slotsService";
import { dispatch } from "@/store";
import { onEnqueueSnackbar } from "./slice";
import { uniqueId } from "lodash";
import { membersApiSlice } from "@/store/api/membersService";
import { programsApiSlice } from "@/store/api/programsService";

const NOTIFY_ON_ACTIONS = [
  slotsApiSlice.endpoints.updateSlots.name,
  slotsApiSlice.endpoints.updateSingleSlot.name,
  membersApiSlice.endpoints.updateMember.name,
  membersApiSlice.endpoints.createMember.name,
  programsApiSlice.endpoints.createProgram.name,
  programsApiSlice.endpoints.updateProgram.name,
];

const notificationMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.meta && NOTIFY_ON_ACTIONS.includes(action.meta?.arg?.endpointName)) {
    if (
      action.type.endsWith("/fulfilled")
    ) {
      // Dispatch success notification
      const key = uniqueId();
      dispatch(
        onEnqueueSnackbar({
          key,
          message: "Επιτυχής ενέργεια",
          variant: "success",
          persist: false,
        })
      );
    } else if (action.type.endsWith("/rejected")) {
      // Dispatch error notification
      console.log(action);
      const key = uniqueId();
      dispatch(
        onEnqueueSnackbar({
          key,
          message: `Υπήρξε κάποιο πρόβλημα`,
          variant: "error",
          persist: false,
        })
      );
    }
  }
  // Check if the action type includes '/fulfilled' and if its endpointName is one of the actions we want to notify for.

  return result;
};

export default notificationMiddleware;
