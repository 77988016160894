function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_MEMBERS = "/members";
const ROOTS_ADMINS = "/admins";
const ROOTS_PROGRAMS = "/programs";
const ROOTS_SUBSCRIPTIONS = "/subscriptions";
const ROOTS_CALENDAR = "/calendar";
const ROOTS_ADMIN_CALENDAR = "/admin-calendar";
const ROOTS_PROFILE = "/profile";
const ROOTS_404 = "/404";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

// Admin routes
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
};

export const PATH_PROGRAMS = {
  root: ROOTS_PROGRAMS,
  create: path(ROOTS_PROGRAMS, '/create'),
  edit: path(ROOTS_PROGRAMS, '/edit')
}

export const PATH_SUBSCRIPTIONS = {
  root: ROOTS_SUBSCRIPTIONS,
  create: path(ROOTS_SUBSCRIPTIONS, '/create'),
  edit: path(ROOTS_SUBSCRIPTIONS, '/edit')
}

export const PATH_MEMBERS = {
  root: ROOTS_MEMBERS,
  create: path(ROOTS_MEMBERS, '/create'),
  edit: path(ROOTS_MEMBERS, '/edit')
};

export const PATH_ADMINS = {
  root: ROOTS_ADMINS,
};


export const PATH_ADMIN_CALENDAR = {
  root: ROOTS_ADMIN_CALENDAR
};

// Member routes
export const PATH_CALENDAR = {
  root: ROOTS_CALENDAR
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE
};

export const PATH_NOT_FOUND = {
  root: ROOTS_404
}


