import { Navigate } from "react-router-dom";
// routes
import { PATH_DASHBOARD, PATH_PROFILE } from "../routes/paths";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "./useAuthContext";
import { useGetProfileQuery } from "@/store/api/profileService";
import { UserRole } from "@octomatic-labs/my-ergon-lab-models";
import { useEffect } from "react";

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const { data, refetch } = useGetProfileQuery();

  useEffect(() => {
    refetch();
  }, [isAuthenticated])

  const currentRole = data?.user?.role;

  if (isAuthenticated) {
    if (currentRole === UserRole.MEMBER) {
      return <Navigate to={PATH_PROFILE.root} />;
    }
    else if ([UserRole.ADMIN, UserRole.TRAINER].includes(currentRole)) {
      return <Navigate to={PATH_DASHBOARD.root} />;
    }
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
