import { CreateProgramPayload, EditProgramPayload, Program } from "@octomatic-labs/my-ergon-lab-models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const programsApiSlice = createApi({
  reducerPath: "programsApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Programs", "CurrentProgram"],
  endpoints: (builder) => ({
    getAllPrograms: builder.query<Program[], void>({
      query: () => "programs",
      transformResponse: (response: Program[] | undefined) => {
        return response || [];
      },
      providesTags: ["Programs"],
    }),
    getProgram: builder.query<Program, number>({
      query: (id) => `programs/${id}`,
      providesTags: ["CurrentProgram"],
    }),
    createProgram: builder.mutation<Program, CreateProgramPayload>({
      query: (newProgram) => ({ url: "programs", method: "POST", body: newProgram }),
      invalidatesTags: [{ type: "Programs" }],
    }),
    deleteProgram: builder.mutation<void, number>({
      query: (id) => ({
        url: `programs/${id}`,
        method: "DELETE",
        invalidateTags: [{ type: "Programs" }],
      }),
    }),
    updateProgram: builder.mutation<void, EditProgramPayload>({
      query: (updates) => {
        return { url: `programs/${updates.id}`, method: "PATCH", body: updates };
      },
      invalidatesTags: [
        { type: "Programs" },
        { type: "CurrentProgram" }
      ]
    })
  })
});

export const {
  useGetAllProgramsQuery,
  useGetProgramQuery,
  useCreateProgramMutation,
  useDeleteProgramMutation,
  useUpdateProgramMutation,
} = programsApiSlice;