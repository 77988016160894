import { RootState } from "@/store";
import { User } from "@octomatic-labs/my-ergon-lab-models";
import {
  EntityState,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { membersApiSlice } from "../../../store/api/membersService";

type MembersState = {
  members: EntityState<User> & { current: number | null };
};

const membersAdapter = createEntityAdapter<User>();

const initialState: MembersState = {
  members: membersAdapter.getInitialState({ current: null }),
};

const slice = createSlice({
  name: "Members",
  initialState,
  reducers: {
    setCurrentMember: (state, { payload }) => {
      state.members.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      membersApiSlice.endpoints.getMembers.matchFulfilled,
      (state, action) => {
        membersAdapter.setAll(state.members, action.payload);
      }
    );
    builder.addMatcher(
      membersApiSlice.endpoints.createMember.matchFulfilled,
      (state, action) => {
        console.log(action);
      }
    );
    builder.addMatcher(
      membersApiSlice.endpoints.updateMember.matchFulfilled,
      (state, action) => {
        console.log(action);
      }
    );
    builder.addMatcher(
      membersApiSlice.endpoints.deleteMember.matchFulfilled,
      (state, { payload }) => {
        const { id } = payload as any;

        membersAdapter.removeOne(state.members, id);
      }
    );
  },
});

export const { setCurrentMember } = slice.actions;

export default slice.reducer;

export const selectCurrentMemberId = (state: RootState) =>
  state.members.members.current;

export const {
  selectById: selectMemberById,
  selectAll: selectAllMembers,
  selectEntities: selectAllMembersEntities,
} = membersAdapter.getSelectors((state: RootState) => state.members.members);

export const selectCurrentMember = createSelector(
  [selectAllMembers, selectCurrentMemberId],
  (members, currentMemberId) =>
    members.find((member) => member.id === currentMemberId)
);
