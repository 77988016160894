import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Booking, Profile } from "@octomatic-labs/my-ergon-lab-models";

export const profileApiSlice = createApi({
  reducerPath: "profileApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    getProfile: builder.query<{user: Profile; bookings: Booking[]}, void>({
      query: () => "users/profile",
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation<void, Partial<Profile>>({
      query: (updates) => {
        const { id, ...body } = updates;
        return { url: `users/${id}`, method: "PATCH", body };
      },
      invalidatesTags: [{ type: "Profile" }],
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApiSlice;
