import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { Booking } from "@octomatic-labs/my-ergon-lab-models";

type ProfileState = {
  bookings: EntityState<Booking>;
};

const profileBookingsAdapter = createEntityAdapter<Booking>();

const initialState: ProfileState = {
  bookings: profileBookingsAdapter.getInitialState(),
};

const slice = createSlice({
  name: "Profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});


export default slice.reducer;
