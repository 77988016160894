import {
  CreateUserPayload,
  EditUserPayload,
  Program,
  Subscription,
  User,
} from "@octomatic-labs/my-ergon-lab-models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const membersApiSlice = createApi({
  reducerPath: "membersApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Members", "CurrentMember", "CurrentMemberSubscriptions"],
  endpoints: (builder) => ({
    getMembers: builder.query<User[], void>({
      query: () => "users",
      transformResponse: (response: User[] | undefined) => response || [],
      providesTags: ["Members"],
    }),
    getMember: builder.query<User, number | string>({
      query: (id) => `users/${id}`,
      providesTags: ["CurrentMember"],
    }),
    createMember: builder.mutation<User, CreateUserPayload>({
      query: (newMember) => ({ url: "users", method: "POST", body: newMember }),
      invalidatesTags: [{ type: "Members" }],
    }),
    updateMember: builder.mutation<void, EditUserPayload>({
      query: (updates) => {
        const { id, ...body } = updates;
        return { url: `users/${id}`, method: "PATCH", body };
      },
      invalidatesTags: [
        { type: "Members" },
        { type: "CurrentMember" },
        { type: "CurrentMemberSubscriptions" },
      ],
    }),
    deleteMember: builder.mutation<void, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
        invalidateTags: [{ type: "Members" }],
      }),
    }),
    getMemberSubscriptions: builder.query<Subscription[], number>({
      query: (userId) => `users/${userId}/subscriptions`,
      transformResponse: (response: Subscription[]) => response || [],
      providesTags: ["CurrentMemberSubscriptions"],
    }),
    getMemberPrograms: builder.query<Program[], number>({
      query: (userId) => `users/${userId}/subscriptions`,
      transformResponse: (response: Program[]) => response || [],
    }),
  }),
});

export const {
  useGetMembersQuery,
  useGetMemberQuery,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useGetMemberSubscriptionsQuery,
  useDeleteMemberMutation,
  useGetMemberProgramsQuery
} = membersApiSlice;
