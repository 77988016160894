import { m } from "framer-motion";
// @mui
import { Container, Typography } from "@mui/material";
// components
import { MotionContainer, varBounce } from "../components/animate";

//
import { ForbiddenIllustration } from "@/assets/illustrations.tsx";
import { UserRole } from "@octomatic-labs/my-ergon-lab-models";
import { useGetProfileQuery } from "@/store/api/profileService";
import { PATH_DASHBOARD, PATH_PROFILE } from "@/routes/paths";
import { Navigate } from "react-router-dom";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: UserRole[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent = true,
  roles,
  children,
}: RoleBasedGuardProp) {
  const { data } = useGetProfileQuery();

  const currentRole = data?.user?.role;

  if (typeof roles !== "undefined" && !roles.includes(currentRole)) {
    let navigateTo = null;
    if (currentRole === UserRole.MEMBER) {
      navigateTo = PATH_PROFILE.root;
    } else if (
      currentRole === UserRole.ADMIN ||
      currentRole === UserRole.TRAINER
    ) {
      navigateTo = PATH_DASHBOARD.root;
    }
    if (navigateTo) {
      return hasContent ? <Navigate to={navigateTo} /> : null;
    }
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: "center" }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Απαγορεύεται η πρόσβαση
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            Δεν έχετε τα κατάλληλα δικαιώματα χρήστη για να επισκεφθείτε αυτή τη
            σελίδα
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  } else {
    return <> {children} </>;
  }
}
