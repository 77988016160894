import Iconify from '@/components/Iconify';
import { PATH_ADMINS, PATH_ADMIN_CALENDAR, PATH_CALENDAR, PATH_DASHBOARD, PATH_MEMBERS, PATH_PROFILE, PATH_PROGRAMS, PATH_SUBSCRIPTIONS } from '@/routes/paths';
import { UserRole } from '@octomatic-labs/my-ergon-lab-models';

// ----------------------------------------------------------------------

const getIcon = (icon: string) => <Iconify icon={icon} />;

const ICONS = {
  calendar: getIcon('eva:calendar-outline'),
  pie: getIcon('ant-design:dashboard-outlined'),
  user: getIcon('eva:person-outline'),
  users: getIcon('eva:people-outline'),
  admins: getIcon('ri:admin-line'),
  programs: getIcon('iconoir:gym'),
  subscriptions: getIcon('mdi:file-user')
};

const navConfig = [
  {
    items: [
      { title: 'Πίνακας ελέγχου', path: PATH_DASHBOARD.root, icon: ICONS.pie, roles: [UserRole.ADMIN, UserRole.TRAINER] },
      { title: 'Admins', path: PATH_ADMINS.root, icon: ICONS.admins, roles: [UserRole.ADMIN] },
      { title: 'Αθλούμενοι', path: PATH_MEMBERS.root, icon: ICONS.users, roles: [UserRole.ADMIN, UserRole.TRAINER] },
      { title: 'Προγράμματα', path: PATH_PROGRAMS.root, icon: ICONS.programs, roles: [UserRole.ADMIN, UserRole.TRAINER] },
      { title: 'Συνδρομές', path: PATH_SUBSCRIPTIONS.root, icon: ICONS.subscriptions, roles: [UserRole.ADMIN, UserRole.TRAINER] },
      { title: 'Συνεδρίες', path: PATH_ADMIN_CALENDAR.root, icon: ICONS.calendar, roles: [UserRole.ADMIN, UserRole.TRAINER] },
      { title: 'Πρόγραμμα προπονήσεων', path: PATH_CALENDAR.root, icon: ICONS.calendar, roles: [UserRole.MEMBER] },
      { title: 'Ο λογαριασμός μου', path: PATH_PROFILE.root, icon: ICONS.user, roles: [UserRole.MEMBER] },
    ],
  },
];

export default navConfig;
