import { RootState } from "@/store";
import { subscriptionsApiSlice } from "@/store/api/subscriptionsService";
import { Subscription } from "@octomatic-labs/my-ergon-lab-models";
import { EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

type SubscriptionsState = {
  subscriptions: EntityState<Subscription>;
}

const subscriptionsAdapter = createEntityAdapter<Subscription>();

const initialState: SubscriptionsState = {
  subscriptions: subscriptionsAdapter.getInitialState()
};

const slice = createSlice({
  name: "Subscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      subscriptionsApiSlice.endpoints.getSubscriptions.matchFulfilled,
      (state, action) => {
        subscriptionsAdapter.setAll(state.subscriptions, action.payload);
      }
    )
  }
});

export default slice.reducer;

export const {
  selectById: selectSubscriptionById,
  selectAll: selectAllSubscriptions,
} = subscriptionsAdapter.getSelectors((state: RootState) => state.subscriptions.subscriptions)