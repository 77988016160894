import { useSnackbar } from "@/components/snackbar";
import { useDispatch, useSelector } from "@/store";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  onCloseSnackbar,
  onRemoveSnackbar,
  selectAllNotifications,
} from "../store/slice";
import { IconButtonAnimate } from "@/components/animate";
import Iconify from "@/components/Iconify";

let displayed = [];
const NotificationHandler = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useSelector(selectAllNotifications); // adjust based on your state shape

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, title, dismissed = false, variant }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ textTransform: "capitalize" }}
            >
              {title || variant}
            </Typography>
            <Typography variant="body2">
              {message}
            </Typography>
          </Box>,
          {
            key,
            variant,
            style: { whiteSpace: "pre-line" },
            onExited: (event, key) => {
              // remove this snackbar from redux store
              dispatch(onRemoveSnackbar(key));
              removeDisplayed(key);
            },
            action: (key) => (
              <IconButtonAnimate
                size="small"
                onClick={() => {
                  closeSnackbar(key);
                  dispatch(onCloseSnackbar(key));
                }}
                sx={{ p: 0.5 }}
              >
                <Iconify icon={"eva:close-fill"} />
              </IconButtonAnimate>
            ),
          }
        );

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default NotificationHandler;
